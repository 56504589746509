const links = [
    {
        label: 'Linkedin',
        url: 'https://www.linkedin.com/in/ramosht/'
    },
    {
        label: 'Github',
        url: 'https://github.com/ramosht'
    },
    {
        label: 'Twitter',
        url: 'https://twitter.com/ramosht_'
    },
    {
        label: 'Instagram',
        url: 'https://instagram.com/theguilhermeramos'
    },
    {
        label: 'Instagram',
        url: 'https://instagram.com/guilhermeramosphotography'
    },
]

export default links;